.rc-slider {
  position: relative;
  height: 12px;
  padding: 5px 0;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 50px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rangeSlider > .rc-slider {
  padding: 10px 10px;
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  /* left: 15%; */
  background-color: transparent;
  height: 12px;
  border-radius: 5px;
  border: 3px solid #e9e9e9;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 12px;
  background-color: #34bbda;
  border: 1px solid #e9e9e9;
}
.rc-slider-handle {
  position: absolute;
  width: 5px;
  height: 16px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -2px;
  cursor: grab;
  border-radius: 3px;
  border: solid 2px #292e4d;
  background-color: #292e4d;
  touch-action: pan-x;
}
.x-axis-zoom > .rc-slider-handle {
  position: absolute;
  width: 50px;
  height: 9px;
  cursor: -webkit-grab;
  margin-top: 0px;
  cursor: grab;
  border-radius: 20px;
  border: 0px;
  background-color: #292e4d;
  touch-action: pan-x;
  padding-top: 4px;
}

.x-axis-zoom > .rc-slider-handle::after {
  content: '';
  width: 1px;
  height: 7px;
  background: grey;
  display: block;
  top: 49%;
  left: 46%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
}
.x-axis-zoom > .rc-slider-handle::before {
  content: '';
  width: 1px;
  height: 7px;
  background: grey;
  display: block;
  top: 49%;
  left: 54%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
}

.x-axis-zoom > .rc-slider-track {
  background-color: #e0e0e0;
  position: absolute;
  left: 0;
  background-color: transparent;
  border: 1px solid transparent;
  width: 75%;
}
.x-axis-zoom > .rc-slider-rail {
  height: 9px;
  background-color: #e0e0e0;
  border-radius: 5px;
  border: 0px;
}
.video-progress-slider > .rc-slider-rail {
  height: 5px;
}
.video-progress-slider > .rc-slider-track {
  /* time that has passed */

  height: 7px;
  border-radius: 5px;
  border: none;
  padding-top: 1px;
  margin-left: 10px;
}
.video-progress-slider > .rc-slider-rail {
  width: 100%;
  margin: 0 10px 0 10px;
  height: 7px;
  background-color: #e0e0e0;
}
.video-progress-slider > .rc-slider .video-progress-slider {
  width: 70%;
  margin: 0 40px 0 40px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.video-progress-slider > .rc-slider-handle {
  position: absolute;
  width: 10px;
  height: 9px;
  cursor: auto;
  margin-top: -1px;
  margin-left: 10px;
  border-radius: 10px;
  border: 0px;
  background-color: white;
  padding-top: 4px;
  left: 2.5%;
}
.video-progress-slider > element.style {
  left: 2.5%;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #34bbda;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 8px;
  border: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  box-shadow: none;
  background-color: #ccc;
  border-color: gray;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 12px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #292e4d;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #292e4d;
}
